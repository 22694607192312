import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import joinTeam from '@/views/joinTeam.vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {join} from 'path';
const pathBase = process.env.VUE_APP_PATH_BASE;

const routes: Array<RouteRecordRaw> = [
  {
    path: join(pathBase, '/'),
    name: 'joinTeam',
    component: joinTeam,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
