import type { AxiosError } from 'axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import { message } from 'ant-design-vue';

const $http = axios.create({
  timeout: 40000,
  baseURL: process.env.VUE_APP_BASEURL,
  withCredentials: true,
});

// 接口错误处理统一函数
function errorHandler(error: AxiosError) {
  const errorInfo = error.response?.data?.error; // 错误信息，error.response 可能为 undefined，例如 404
  const config = error.config;

  setTimeout(() => {
    if (config.isHandled) return; // 错误信息是否交由业务代码处自行处理

    if (errorInfo) {
      // 弹出错误信息
      if (!config.isHideErrorMessage) {
        message.error({
          content: `${errorInfo.message}，状态码：${error.response?.status}`,
          key: 'errorMessage',
        });
      }
    } else {
      message.error({
        content: error.message || '未知错误',
        key: 'errorMessage',
      });
    }
  });
  return Promise.reject(errorInfo);
}

$http.interceptors.request.use((config) => {
  // 接口验证，无unique参数后端将进行拦截
  config.headers = { ...(config.headers || {}), unique: Date.now().toString(), ssounique: Date.now().toString() };

  // 接口是否需要鉴权
  // if (!config.noAuth) {
  //   const authorization = Cookies.get('Authorization');
  //   config.headers.Authorization = `Bearer ${authorization}`;
  // }

  return config;
});

$http.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  (error) => {
    return errorHandler(error);
  },
);

export default $http;
