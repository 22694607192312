
import { defineComponent, ref } from 'vue';
import userProtocol from '@/config/userProtocol';

export default defineComponent({
  name: 'userProtocolDialog',

  setup() {
    const userProtocolDialog = ref(false);
    const bodyStyle = {
      maxHeight: '70vh',
      overflow: 'auto',
    };

    return {
      userProtocolDialog,
      bodyStyle,
      userProtocol,
    };
  },
});
