
import { defineComponent, ref, reactive } from 'vue';
import userProtocolDialog from '@/components/dialog/userProtocolDialog.vue';
import { appStore } from '@/store/app';
import { message } from 'ant-design-vue';
import verificationCode from '@/components/dialog/composables/verificationCode';
import $http from '@/http';

export default defineComponent({
  name: 'bindPhoneDialog',

  components: {
    userProtocolDialog,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  emits: {
    authCodeSuccess: null,
    'update:visible': null,
  },

  setup(props, { emit }) {
    const formRef = ref();
    const agreeProtocol = ref(true);
    const btnLoading = ref(false);
    const accountForm = reactive({
      phoneNumber: '',
      smsCaptcha: '',
      pageFlag: '',
      key: '', //邀请码
    });
    const accountRules = {
      phoneNumber: [
        {
          required: true,
          message: '请输入手机号',
          trigger: 'change',
        },
        {
          pattern: /^1([3456789])\d{9}$/,
          message: '请输入正确的手机号',
          trigger: 'change',
        },
      ],
      smsCaptcha: [
        {
          required: true,
          message: '请输入验证码',
          trigger: 'change',
        },
      ],
    };
    const { desc, getVerificationCode } = verificationCode(accountForm);

    const getCode = () => {
      accountForm.pageFlag = String(new Date().getTime());
      getVerificationCode(accountForm.pageFlag);
    };

    const submit = () => {
      if (!agreeProtocol.value) {
        message.error('请先勾选用户协议');
        return;
      }
      if (!accountForm.pageFlag) {
        message.error('请先获取验证码');
        return;
      }
      btnLoading.value = true;
      accountForm.key = appStore.state.inviterKey;
      formRef.value
        .validate()
        .then(() => {
          $http
            .post(`/api/team/join-team-by-login`, { ...accountForm }, { isHideErrorMessage: true })
            .then((res) => {
              emit('authCodeSuccess', res);
            })
            .catch((error) => {
              message.error(error?.message);
              emit('authCodeSuccess', error);
            });
        })
        .catch(() => {})
        .finally(() => {
          btnLoading.value = false;
        });
    };

    return {
      formRef,
      agreeProtocol,
      btnLoading,
      accountForm,
      accountRules,
      desc,
      getVerificationCode,
      close,
      submit,
      getCode,
    };
  },
});
