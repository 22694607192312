import AppStateTypes from '@/types/store/appStateTypes';
import { MutationTree } from 'vuex';
import Cookies from 'js-cookie';

const appMutations: MutationTree<AppStateTypes> = {
  /**
   * 设置 Cookie
   * @param state
   * @param payload
   */
  _setCookie(state, payload) {
    Cookies.set('Authorization', payload.accessToken);
  },

  /**
   * 设置邀请链接加密参数
   * @param state
   * @param payload
   */
  _setInviterKey(state, payload) {
    state.inviterKey = payload;
  },
};

export default appMutations;
