import type { InjectionKey } from 'vue';
import type AppStateTypes from '@/types/store/appStateTypes';
import type { Store } from 'vuex';
import { createStore, useStore } from 'vuex';
import appState from '@/store/app/appState';
import appMutations from '@/store/app/appMutations';
import appActions from '@/store/app/appActions';

export const appKey: InjectionKey<Store<AppStateTypes>> = Symbol();

export const appStore = createStore<AppStateTypes>({
  state: appState,
  mutations: appMutations,
  actions: appActions,
});

export default function appUseStore() {
  return useStore(appKey);
}
