
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import appUseStore from '@/store/app';
import joinTeamDialog from '@/components/dialog/joinTeamDialog.vue';
import { message } from 'ant-design-vue';
import bindPhoneDialog from '@/components/dialog/bindPhoneDialog.vue';
import { getUrlParams } from '@/tools/index';
import authCode from '@/components/authCode.vue';

interface urlParamsType {
  code: string;
}

export default defineComponent({
  name: 'joinTeamV2',

  components: {
    joinTeamDialog,
    bindPhoneDialog,
    authCode,
  },
  created() {
    this.getTeamInfo();
  },
  setup() {
    const appStore = appUseStore();
    const { query } = useRoute();
    const isJoined = ref(false);
    const joinMsg = ref('');
    const bindPhoneDialog = ref(false);
    const authCodeDialog = ref(false); //短信验证登录
    const name = ref('');
    const logo = ref('');
    const urlParams = ref(getUrlParams(window.location.href) as urlParamsType);
    console.log(urlParams.value.code);
    // 保存邀请的加密key
    appStore.commit('_setInviterKey', urlParams.value.code);

    //获取团队信息
    const getTeamInfo = () => {
      appStore
        .dispatch('_getTeamInfo', urlParams.value.code)
        .then((res) => {
          const team = JSON.parse(res.data);
          name.value = team.name;
          logo.value = team.logo;
        })
        .catch(() => {});
    };

    const scanCodeSuccess = () => {
      appStore
        .dispatch('_bindTeamV3')
        .then((res) => {
          // 加入成功
          if (res.code === 200) {
            isJoined.value = true;
            joinMsg.value = res.msg;
          }

          // 未绑定手机号
          else if (res.code === 201) {
            bindPhoneDialog.value = true;
          }

          // 其它，报错
          else if (res.code === 500) {
            message.error({
              content: res.msg,
              key: 'bindTeamError',
            });
          }
        })
        .catch(() => {});
    };
    const bindSuccess = ($event: { code: number; msg: string }) => {
      isJoined.value = true;
      joinMsg.value = $event.msg;
    };

    const showAuthCode = () => {
      authCodeDialog.value = true;
    };

    const authCodeSuccess = (res: any) => {
      console.log(res);
      // 加入成功
      if (res.code === 200) {
        isJoined.value = true;
        joinMsg.value = res.msg;
      }

      // 未绑定手机号
      else if (res.code === '201') {
        bindPhoneDialog.value = true;
      }

      // 其它，报错
      else if (res.code === '500') {
        message.error({
          content: res.msg,
          key: 'bindTeamError',
        });
      }
    };

    return {
      isJoined,
      joinMsg,
      name,
      logo,
      urlParams,
      bindPhoneDialog,
      showAuthCode,
      bindSuccess,
      getTeamInfo,
      authCodeDialog,
      scanCodeSuccess,
      authCodeSuccess,
    };
  },
});
