import { name, companyName, addressName } from "@/config/index";
const userProtocol = `
<p>
    本《用户协议》是您(下称“用户”)使用${companyName}开发提供的互联网服务产品——“${name}”的网站、客户端、APP、微信公众号、微信小程序等产品的各项功能及服务，在注册用户(又名“帐号”，下统称“帐号”)和使用${name}产品时与${companyName}所签署的协议。
</p>
<h3>
    一、重要须知---在签署本协议之前，${name}正式提醒用户：
</h3>
<p>
    1.1 您应认真阅读(未成年人应当在监护人陪同下阅读)、充分理解本《用户协议》中各条款，特别是免除或者限制${name}责任的免责条款，用户的权利限制条款，约定争议解决方式、司法管辖、法律适用的条款。
</p>
<p>
    1.2 除非您接受本协议，否则用户无权也无必要继续接受${name}的服务，可以退出本次服务。用户点击接受并继续使用${name}的服务，视为用户已完全的接受本协议。
</p>
<p>
    1.3 本协议在您开始使用${name}的服务，注册成为${name}产品的用户时即产生法律效力，请您慎重考虑是否接受本协议，如不接受本协议的任一条款，请自动退出并不再接受${name}的任何服务。
</p>
<p>
    1.4 在您签署本协议之后，此文本可能因国家政策、产品以及履行本协议的环境发生变化而进行修改，我们会将修改后的协议发布在本网站上，若您对修改后的协议有异议的，请立即停止登录、使用${name}产品及服务，若您登录或继续使用${name}产品，视为认可修改后的协议。
</p>
<h3>
    二、关于“帐号”及“付费会员”资格
</h3>
<p>
    2.1 ${name}在旗下业务平台（包括但不限于${name}）提供用户注册通道，用户在认可并接受本协议之后，有权选择未被其他用户使用过的手机号码或字母符号组合作为用户的帐号，并自行设置符合安全要求的密码。用户设置的帐号、密码是用户用以登录${name}产品，接受${name}服务的凭证。
</p>
<p>
    1)用户可通过各种已有和未来新增的渠道注册${name}账号及付费成为会员。
</p>
<p>
    2)用户在加入付费会员时，须仔细阅读并确认相关的用户协议和使用方法。
</p>
<p>
    3)用户通过网络填写并提交注册表，表中所填写的个人资料与内容必须真实有效，否则${name}有权拒绝其申请，有权撤销其账号或付费会员资格，并不予任何赔偿或退还会员费。用户的个人资料发生变化，应及时修改相关资料，否则由此造成的会员权力不能全面有效行使的责任由会员自己承担，${name}有权因此取消其会员资格，并不予任何赔偿或退还会员费。
</p>
<p>
    4)成为付费会员后，会员有权利不接受${name}的产品或服务，可申请取消会员服务，但不得向${name}主张退还任何服务费用。
</p>
<p>
    2.2 用户在注册了${name}帐号并不意味获得全部${name}产品服务的授权，仅是取得了接受${name}服务的身份，用户在登录相关网页、加载应用、下载安装软件时将按需要另行签署单个产品的授权协议。
</p>
<p>
    2.3 ${name}账户仅限于在${name}网站上注册用户本人使用，禁止赠与、借用、租用、转让或售卖。如果${name}发现或者有理由怀疑使用者并非帐号初始注册人，有权在未经通知的情况下，暂停或终止向用户提供服务，并有权注销该帐号，而无需向该帐号使用人承担任何法律责任，由此带来的包括但不限于用户通讯中断、用户资料和信息等清空等损失由用户自行承担。
</p>
<p>
    2.4 用户有责任维护其个人帐号、密码的安全性与保密性，用户就其帐号及密码项下之一切活动负全部责任，包括用户数据的修改发表的言论以及其他所有的损失。用户应重视${name}帐号密码保护。用户如发现他人未经许可使用其帐号或发生其他任何安全漏洞问题时应立即通知${name}。如果用户在使用${name}服务时违反上述规则而产生任何损失或损害，${name}不承担任何责任。
</p>
<p>
    2.5 用户帐号在丢失或遗忘密码后，可遵照${name}的申诉途径及时申诉请求找回帐号。用户应提供能增加帐号安全性的个人密码保护资料。用户可以凭初始注册资料及个人密码保护资料填写申诉单向${name}申请找回帐号，${name}的密码找回机制仅负责识别申诉单上所填资料与系统记录资料的正确性，而无法识别申诉人是否系真正帐号注册使用人。对用户因被他人冒名申诉而致的任何损失，${name}不承担任何责任，用户知晓帐号及密码保管责任在于用户，${name}并无义务保证帐号丢失或遗忘密码后用户一定能通过申诉找回帐号。
</p>
<h3>
    三、用户在使用${name}产品或服务时，应当遵守《中华人民共和国宪法》、《中华人民共和国刑法》、《中华人民共和国民法总则》、《中华人民共和国合同法》、《中华人民共和国著作权法》、《中华人民共和国电信条例》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》等相关法律法规。用户不得利用${name}的服务产品从事违反法律法规、政策以及侵犯他人合法权益的行为，包括但不限于下列行为：
</h3>
<p>
    3.1 利用${name}服务产品发表、传送、传播、储存反对宪法所确定的基本原则、危害国家安全、国家统一、社会稳定、煽动民族仇恨、民族歧视、破坏民族团结的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容或者设置含有上述内容的网名、角色名。
</p>
<p>
    3.2 利用${name}服务发表、传送、传播、储存侵害他人知识产权、商业机密、肖像权、隐私权等合法权利或其他道德上令人反感的内容。
</p>
<p>
    3.3 进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐户;未经允许进入公众计算机网络或者他人计算机系统删除、修改、增加存储信息;未经许可，企图探查、扫描、测试本${name}产品或服务软件系统或网络的弱点或其它实施破坏网络安全的行为;企图干涉、破坏${name}产品或服务软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为;伪造TCP/IP数据包名称或部分名称;自行或利用其他软件对${name}提供产品进行反向破解等行为。
</p>
<p>
    3.4 进行诸如发布广告、推广信息、销售商品的行为，或者进行任何非法侵害${name}利益的行为。
</p>
<p>
    3.5 进行任何违法以及侵犯他人、公司、社会团体、组织的合法权益的行为或者法律、行政法规、规章、条例以及任何具有法律效力之规范所限制或禁止的行为。
</p>
<p>
    3.6在任何情况下，如果${name}有理由认为用户的行为，包括但不限于用户的言论或其它行为，违反或可能违反法律法规、国家政策以及本协议的任何规定，${name}可在不经事先通知的情形下，有权终止向用户提供服务。同时，${name}有权依合理判断对违反有关法律法规或本协议规定的行为追究相关用户的法律责任，并有权依据法律法规向有关部门报告等，由此而产生的一切法律责任由用户承担。
</p>
<h3>
    四、${name}声明
</h3>
<p>
    4.1 用户须知，为了向您提供有效的服务，使用${name}提供的产品或服务会使用您终端设备的处理器和带宽等资源，在使用过程中可能产生数据流量的费用，用户需自行向电信运营商了解相关资费信息，并自行承担相关费用。
</p>
<p>
    4.2 用户须知，在使用${name}服务时，可能存在有来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯(包括知识产权)的匿名或冒名的信息的风险，用户须承担以上风险。${name}对服务不作担保，不论是明确的或隐含的，包括所有有关信息真实性、适当性、适于某一特定用途、所有权和非侵权性的默示担保和条件，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害，${name}不承担任何责任。
</p>
<p>
    4.3 使用${name}服务必须遵守国家有关法律法规和政策等，维护国家利益，保护国家安全，并遵守本协议，对于用户违法行为或违反本协议的使用(包括但不限于言论发表、传送等)而引起的一切责任，由用户全部承担。
</p>
<p>
    4.4 ${name}提供的所有信息、资讯、内容和服务均来自互联网，并不代表${name}的观点，${name}对其真实性、合法性概不负责，亦不承担任何法律责任。
</p>
<p>
    4.5 ${name}所提供的产品和服务也属于互联网范畴，也易受到各种安全问题的困扰，包括但不限于：
</p>
<p>
    1)个人资料被不法分子利用，造成现实生活中的骚扰;
</p>
<p>
    2)哄骗、破译密码;
</p>
<p>
    3)下载安装的其它软件中含有“特洛伊木马”等病毒程序，威胁到个人计算机上信息和数据的安全，继而威胁对${name}产品或服务的使用；
</p>
<p>
    4)发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；
</p>
<p>
    5)由于网络信号不稳定、网络带宽小等网络原因，所引起的账号登录失败、资料同步不完整、页面打开速度慢等风险；
</p>
<p>
    6)以及其他类网络安全困扰问题
</p>
<p>
    对于发生上述情况的，用户应当自行承担责任。
</p>
<p>
    维护软件安全与正常使用是${name}和用户的共同责任，${name}将按照行业标准合理审慎地采取必要技术措施保护用户的终端设备信息和数据安全，但是${name}并不能就此提供完全保证。
</p>
<p>
    4.6 用户须明白，${name}为了整体运营的需要，有权在公告通知后，在不事先通知用户的情况下修改、中断、中止或终止服务，而无须向用户或第三方负责，${name}不承担任何赔偿责任。
</p>
<p>
    4.7 为了改善用户体验、完善服务内容，${name}将不断努力开发新的服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。为了保证产品及服务的安全性和功能的一致性，${name}有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制，您对此没有异议。
</p>
<p>
    4.8 用户应理解，互联网技术存在不稳定性，可能导致政府管制、政策限制、病毒入侵、黑客攻击、服务器系统崩溃或者其他现今技术无法解决的风险发生。由以上原因可能导致${name}服务中断或帐号信息损失，对此非人为因素引起的用户损失由用户自行承担责任。
</p>
<p>
    4.9 关于服务退款说明：用户购买的商品为虚拟数据服务，购买后不支持退订、转让、退换，请斟酌确认。一经开通账号会员使用权限，权限生效后，即代表用户已享受到${name}的数据服务，${name}不接受用户任何借口为理由的退款请求。
</p>
<p>
    4.10 关于${name}服务促销活动的说明：用户须明白，${name}为了整体运营的需要，有权在节假日/重大节日发起相关运营或促销活动，${name}无需提前事先通知已付费用户且不承担服务促销活动的任何损失，促销活动解释所有权归属${name}。
</p>
<h3>
    五、知识产权
</h3>
<p>
    5.1 ${name}对其旗下运营的网页、应用、软件等产品和服务享有知识产权（包括著作权、商标权、专利权、商业秘密等知识产权）。受中国法律法规和相应的国际条约的保护。未经${name}或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
</p>
<p>
    5.2用户不得对${name}服务涉及的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等。
</p>
<p>
    5.3 用户只能在本《用户协议》以及相应的授权许可协议授权的范围内使用${name}知识产权，未经授权超范围使用的，构成对${name}的侵权。${name}有权追究相关侵权的人法律责任。
</p>
<p>
    5.4 用户在使用${name}产品服务时发表上传的文字、图片、视频、软件以及表演等信息，用户的发表、上传行为是对${name}服务平台的授权，为非独占性、永久性的授权，该授权可转授权。${name}可将前述信息在${name}旗下的所有服务平台上使用，可再次编辑后使用，也可以由${name}授权给合作方使用。
</p>
<p>
    5.5 用户应保证，在使用${name}产品服务时上传的文字、图片、视频、软件以及表演等的信息不侵犯任何第三方知识产权，包括但不限于商标权、著作权等。若用户在使用${name}产品服务时上传的文字、图片、视频、软件以及表演等的信息中侵犯第三方知识产权，${name}有权移除该侵权产品，并对此不负任何责任。用户应当负责处理前述第三方的权利主张，承担由此产生的全部费用，包括但不限于侵权赔偿、律师费及其他合理费用，并保证${name}不会因此而遭受任何损失。
</p>
<p>
    5.6 任何单位或个人认为通过${name}提供服务的内容可能涉嫌侵犯其知识产权或信息网络传播权，应该及时向${name}提出书面权利通知投诉，并提供身份证明、权属证明及详细侵权情况证明。
</p>
<h3>
    六、隐私保护
</h3>
<p>
    ${name}非常重视用户的隐私权，用户在享受${name}提供的服务时可能涉及用户的隐私，因此请用户仔细阅读本隐私保护条款。
</p>
<p>
    6.1 请用户注意勿在使用${name}服务中透露自己的各类财产帐户、银行卡、信用卡、第三方支付账户及对应密码等重要信息资料，否则由此带来的任何损失由用户自行承担。
</p>
<p>
    6.2 用户的帐号、密码属于保密信息，${name}会努力采取积极的措施保护用户帐号、密码的安全，但是，${name}并不能就此提供完全保证。
</p>
<p>
    6.3 互联网的开放性以及技术更新速度快，因非${name}可控制的因素导致用户信息泄漏的，${name}不承担任何责任。
</p>
<p>
    6.4 用户在使用${name}服务时不应将自认为隐私的信息发表、上传至${name}，也不应将该等信息通过${name}的服务传播给其他人，由于用户的行为引起的隐私泄漏，由用户自行承担责任。
</p>
<h3>
    七、免责声明
</h3>
<p>
    用户充分了解并同意:
</p>
<p>
    7.1 关于产品和服务，${name}不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。用户对账号的使用行为必须自行承担相应风险。
</p>
<p>
    7.2 用户应对本服务所提供的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。是否使用本网站任何文章或资料应由用户自行考虑且自负风险，因任何文章或资料使用而导致的用户微信公众平台账号之任何损失或数据流失等后果，由用户自行承担，${name}无法且不会对因前述风险而导致的任何损失或损害承担责任。
</p>
<p>
    7.3 用户自${name}提供的产品或服务所取得的任何建议或信息，无论是书面或口头形式，除非本协议有明确规定，将不构成本协议以外之任何保证。
</p>
<p>
    7.4 因用户使用${name}提供的产品或服务时，本软件可能会调用第三方系统或者通过第三方支持用户的使用或访问，使用或访问的结果由该第三方提供，${name}不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，与${name}无关，${name}不承担任何责任。
</p>
<p>
    7.5 ${name}的免费版和分发版(vip)只是账号数和团队人数不同，其他功能完全一样。全能版(vip)则是在分发版(vip)的基础上增加了爆文数据模块。${name}只是提供增加效率、便捷的工具，部分功能无法与平台做到一模一样，请悉知，这不构成退款理由。 根据《网络交易管理办法》第二章第一节第十六条：网络商品经营者销售商品，在线下载或者消费者拆封的音像制品，计算机软件等数字化商品，不支持七天无理由退款。
</p>
<h3>
    八、其他
</h3>
<p>
    8.1 用户注册并使用了${name}的商品或服务，即表示您已阅读并同意受本协议的约束。
</p>
<p>
    8.2 本协议签订地为中华人民共和国${addressName}。
</p>
<p>
    8.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
</p>
<p>
    8.4 若您和${name}之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
</p>
<p>
    8.5本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
</p>
<p>
    8.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
</p>
`;

export default userProtocol;
