import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-078dc629"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "protocol f12 flex ac jc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_icon = _resolveComponent("my-icon")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_user_protocol_dialog = _resolveComponent("user-protocol-dialog")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "bind-phone-dialog",
    title: "设置账号信息",
    width: 460,
    visible: _ctx.visible,
    footer: null,
    onCancel: _ctx.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.accountForm,
          rules: _ctx.accountRules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { name: "phoneNumber" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.accountForm.phoneNumber,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accountForm.phoneNumber) = $event)),
                  placeholder: "手机号"
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_my_icon, { type: "iconshoujihaox" })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { name: "smsCaptcha" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_input, {
                    value: _ctx.accountForm.smsCaptcha,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accountForm.smsCaptcha) = $event)),
                    placeholder: "验证码"
                  }, {
                    prefix: _withCtx(() => [
                      _createVNode(_component_my_icon, { type: "iconyanzhengmax" })
                    ]),
                    _: 1
                  }, 8, ["value"]),
                  _createElementVNode("div", {
                    class: "get-sms absolute pointer",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.getCode && _ctx.getCode(...args)))
                  }, _toDisplayString(_ctx.desc), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { name: "password" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.accountForm.password,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.accountForm.password) = $event)),
                  type: "password",
                  placeholder: "密码"
                }, {
                  prefix: _withCtx(() => [
                    _createVNode(_component_my_icon, { type: "iconmimax" })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_checkbox, {
                    checked: _ctx.agreeProtocol,
                    "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.agreeProtocol) = $event)),
                    style: {"color":"#98aabb"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("我已阅读并同意")
                    ]),
                    _: 1
                  }, 8, ["checked"]),
                  _createVNode(_component_user_protocol_dialog)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  class: "submit",
                  block: "",
                  type: "primary",
                  onClick: _ctx.submit,
                  loading: _ctx.btnLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("加入团队")
                  ]),
                  _: 1
                }, 8, ["onClick", "loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onCancel"]))
}