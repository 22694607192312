import { ref, onUnmounted } from 'vue';
import appUseStore from '@/store/app';
import Cookies from 'js-cookie';

export default function checkScanCodeStatus() {
  const appStore = appUseStore();
  const isScanCodeSuccess = ref(false); // 是否扫码成功
  const isPauseCheck = ref(false); // 是否暂停轮询

  /**
   * 轮询
   */
  const pollingScanStatus = async () => {
    // 如果已暂停轮询，return
    if (isPauseCheck.value) {
      return;
    }

    const res = await appStore.dispatch('_getWxScanStatus', { qtype: 2 }).catch(() => {});

    if (res) {
      if (res.isScan === 1) {
        isScanCodeSuccess.value = true;
      } else {
        isScanCodeSuccess.value = false;

        setTimeout(() => {
          pollingScanStatus(); // 轮询
        }, 2000);
      }
    }
  };

  /**
   * 开始轮询
   */
  const startPollingScanStatus = async () => {
    isPauseCheck.value = false;
    await pollingScanStatus();
  };

  /**
   * 暂停轮询
   */
  const stopPollingScanStatus = () => {
    isPauseCheck.value = true;
  };

  onUnmounted(() => {
    stopPollingScanStatus();
  });

  return {
    isScanCodeSuccess,
    startPollingScanStatus,
    stopPollingScanStatus,
  };
}
