import type AppStateTypes from '@/types/store/appStateTypes';
import type RootStateTypes from '@/types/store/rootStateTypes';
import type { ActionTree } from 'vuex';
import $http from '@/http';
import Cookies from 'js-cookie';

const appActions: ActionTree<AppStateTypes, RootStateTypes> = {
  //获取团队信息
  _getTeamInfo(context, payload) {
    return $http.get(`/api/team/get-invite-info/${payload}`, { noAuth: true });
  },

  /**
   * 获取微信二维码
   * @param context
   * @param payload
   */
  _getWxCode(context, payload) {
    return $http.get('api/weixin/QrcodeTicket', {
      params: payload,
    });
  },

  /**
   * 获取扫码状态
   * @param context
   * @param payload
   */
  _getWxScanStatus(context, payload) {
    return $http.get('/api/weixin/QrcodeScan', {
      params: payload,
    });
  },

  /**
   * 加入团队
   * @param context
   */
  _bindTeam({ state }) {
    return $http.post('/api/team/bind-team-v2', {
      key: state.inviterKey,
    });
  },

  /**
   * 加入团队
   * @param context
   */
  _bindTeamV3({ state }) {
    return $http.post('/api/team/bind-team-v3', {
      key: state.inviterKey,
    });
  },

  /**
   * 获取验证码
   * @param context
   * @param payload
   */
  _getSmsCode(context, payload) {
    return $http.post('api/passport/sms', payload);
  },

  /**
   * 绑定手机号并且加入团队
   * @param context
   * @param payload
   */
  _bindPhoneTeam({ state }, payload) {
    return $http.post('/api/team/bind-phone-team-v2', {
      ...(payload || {}),
      key: state.inviterKey,
    });
  },
};

export default appActions;
