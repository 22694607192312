//获取地址参数
export const getUrlParams = (url: string) => {
  const obj = {} as any;
  const paramsArr = url.split('?')[1].split('&');
  paramsArr.forEach((item) => {
    const keyValue = item.split('=');
    obj[keyValue[0]] = keyValue[1];
  });
  return obj;
};
