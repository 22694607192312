
import { defineComponent, ref, watch } from 'vue';
import appUseStore from '@/store/app';
import { wxCodeBaseURL } from '@/config';
import userProtocolDialog from '@/components/dialog/userProtocolDialog.vue';
import checkScanCodeStatus from '@/components/dialog/composables/checkScanCodeStatus';
import Cookies from 'js-cookie';
import $http from '@/http';

export default defineComponent({
  name: 'joinTeamDialog',

  components: {
    userProtocolDialog,
  },

  emits: {
    scanCodeSuccess: null,
    showAuthCode: null,
  },
  created() {
    this.getPublicData();
  },
  setup(props, { emit }) {
    const appStore = appUseStore();
    const joinTeamDialog = ref(false);
    const wxCode = ref('');
    const agreeProtocol = ref(true);
    const isEnableWechatRegisterAndLogin = ref(true); //是否有微信扫码登录

    const { isScanCodeSuccess, startPollingScanStatus, stopPollingScanStatus } = checkScanCodeStatus();

    const getWxCode = async () => {
      const res = await appStore.dispatch('_getWxCode', { qtype: 2 }).catch(() => {});
      if (res) {
        wxCode.value = wxCodeBaseURL + res.ticket;
        Cookies.set('wx_qtype3_2', res.scene_id);
      }
    };

    //获取公共配置
    const getPublicData = () => {
      $http.get(`/api/home/custom-config`, { noAuth: true }).then((res: any) => {
        isEnableWechatRegisterAndLogin.value = res.registerMethod.isEnableWechatRegisterAndLogin === 'True';
      });
    };

    //立即加入
    const joinTeam = () => {
      if (isEnableWechatRegisterAndLogin.value) {
        joinTeamDialog.value = true;
      } else {
        emit('showAuthCode');
      }
    };

    // 监听窗口打开状态
    watch(joinTeamDialog, (value) => {
      if (value) {
        // 获取微信二维码
        getWxCode().then(startPollingScanStatus);
      } else {
        stopPollingScanStatus();
      }
    });

    // 监听扫码状态
    watch(isScanCodeSuccess, (value) => {
      if (value) {
        joinTeamDialog.value = false;

        emit('scanCodeSuccess');
      }
    });

    return {
      joinTeamDialog,
      wxCode,
      agreeProtocol,
      getPublicData,
      isEnableWechatRegisterAndLogin,
      joinTeam,
    };
  },
});
