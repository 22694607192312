
import { defineComponent, ref, reactive, watch } from 'vue';
import userProtocolDialog from '@/components/dialog/userProtocolDialog.vue';
import verificationCode from '@/components/dialog/composables/verificationCode';
import { appStore } from '@/store/app';
import { message } from 'ant-design-vue';

export default defineComponent({
  name: 'bindPhoneDialog',

  components: {
    userProtocolDialog,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  emits: {
    bindSuccess: null,

    'update:visible': null,
  },
  created() {
    //初始化倒计时
    this.reset();
  },
  setup(props, { emit }) {
    const formRef = ref();
    const agreeProtocol = ref(true);
    const btnLoading = ref(false);
    const accountForm = reactive({
      phoneNumber: '',
      smsCaptcha: '',
      password: '',
      invitationCode: '',
      pageFlag: '',
    });
    const accountRules = {
      phoneNumber: [
        {
          required: true,
          message: '请输入手机号',
          trigger: 'change',
        },
        {
          pattern: /^1([3456789])\d{9}$/,
          message: '请输入正确的手机号',
          trigger: 'change',
        },
      ],
      smsCaptcha: [
        {
          required: true,
          message: '请输入验证码',
          trigger: 'change',
        },
      ],
      password: [
        {
          required: true,
          message: '请输入密码',
          trigger: 'change',
        },
      ],
    };

    const { desc, getVerificationCode, resetCountdown } = verificationCode(accountForm);

    const reset = () => {
      resetCountdown();
    };

    const close = () => {
      emit('update:visible', false);
    };

    const getCode = () => {
      accountForm.pageFlag = String(new Date().getTime());
      getVerificationCode(accountForm.pageFlag);
    };

    const submit = () => {
      if (!agreeProtocol.value) {
        message.error('请先勾选用户协议');
        return;
      }
      if (!accountForm.pageFlag) {
        message.error('请先获取验证码');
        return;
      }
      btnLoading.value = true;
      formRef.value
        .validate()
        .then(() => {
          appStore.dispatch('_bindPhoneTeam', accountForm).then((res) => {
            if (res.code === 200) {
              message.success({
                content: res.msg,
              });

              close();
              emit('bindSuccess', res);
            } else {
              message.error({
                content: res.msg,
                key: 'bindPhoneError',
              });
            }
          });
        })
        .catch(() => {})
        .finally(() => {
          btnLoading.value = false;
        });
    };

    return {
      formRef,
      agreeProtocol,
      btnLoading,
      accountForm,
      accountRules,
      desc,
      getVerificationCode,
      close,
      submit,
      getCode,
      reset,
    };
  },
});
