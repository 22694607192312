import { createApp } from 'vue';
import antd from 'ant-design-vue';
import { createFromIconfontCN } from '@ant-design/icons-vue';
import App from './App.vue';
import router from './router';
import { appKey, appStore } from '@/store/app';
import $http from '@/http';

// css
import 'ant-design-vue/dist/antd.css';
import '@/assets/styles/reset.less';
import '@/assets/styles/global.less';

// iconfont 图标库
const myIcon = createFromIconfontCN({
  scriptUrl: 'https://at.alicdn.com/t/font_1726689_ajc8l20d0r7.js',
});

// 创建实例
const app = createApp(App).use(router).use(antd);

// Vuex
app.use(appStore, appKey);

// 注册组件
app.component('my-icon', myIcon);

// 全局属性
app.config.globalProperties.$http = $http;

// 挂载实例
app.mount('#app');
