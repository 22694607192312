import { computed, ref, watch } from 'vue';
import appUseStore from '@/store/app';
import { message } from 'ant-design-vue';

interface AccountInfo {
  smsCaptcha: string;
  phoneNumber: string;
  pageFlag: string;
}

export default function verificationCode(accountInfo: AccountInfo) {
  const appStore = appUseStore();
  const countdown = ref(0);

  const desc = computed(() => {
    if (countdown.value) {
      return `重新获取 ${countdown.value}S`;
    } else {
      return '获取验证码';
    }
  });

  // 是否可以获取验证码
  const canReGetCode = computed(() => {
    return !countdown.value;
  });

  /**
   * 获取短信验证码
   */
  const getVerificationCode = (pageFlag: any) => {
    if (!canReGetCode.value) return;

    // 先验证手机号号码格式
    if (!/^1([3456789])\d{9}$/.test(accountInfo.phoneNumber)) {
      message.error({
        content: '手机号码格式不正确',
        key: 'errorPhoneNumber',
      });
      return;
    }

    accountInfo.pageFlag = pageFlag;

    appStore
      .dispatch('_getSmsCode', {
        pageFlag: accountInfo.pageFlag,
        mobile: accountInfo.phoneNumber,
      })
      .then(() => {
        message.success({
          content: '验证码发送成功，请注意查收',
          key: 'getVerificationCodeSuccess',
        });

        countdown.value = 60;
      })
      .catch(() => {
        message.error({
          content: '验证码发送失败，请稍后重试',
          key: 'getVerificationCodeFail',
        });
      });
  };

  //重置计时
  const resetCountdown = () => {
    countdown.value = 0;
  };

  /**
   * 倒计时
   */
  watch(countdown, (value) => {
    if (value) {
      setTimeout(() => {
        countdown.value--;
      }, 1000);
    }
  });

  return {
    desc,
    getVerificationCode,
    resetCountdown,
  };
}
